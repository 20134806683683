/**
 * TODO: No need for this file at all. This is done to keep everything
 * consistent among TpJobseeker and TpCompany so refactoring them together
 * in the future is easier
 */

import { tpCompanyProfileBuildApproveButton } from './component-factories/tp-company-profile-build-approve-button'

export const TpCompanyProfileApproveButton =
  tpCompanyProfileBuildApproveButton()
